import React from 'react'
import styled from 'react-emotion'
import { Four, One, OneThird, Row, RowGroup, Two } from '../components/Grid'
import ContactForm from '../components/ContactForm'
import { Helmet } from 'react-helmet'
import { CONTRAST_COLOR } from '../constants'
import { SEOH1 } from '../components/Style'
import Link from '../components/Link'
import SEO from '../components/SEO'
import Layout from '../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/testimonials",
      "name": "Testimonials"
    }
  }]
}`

export default () => (
  <Layout>
    <Helmet>
      <script
        type="text/javascript"
        src="https://widget.clutch.co/static/js/widget.js"
      />
    </Helmet>

    <SEO
      path="/testimonials"
      title="Client reviews of 500Tech"
      description="Honest reviews by 500Tech's clients about working with them"
      ld={breadcrumbs}
    />

    <Row>
      <Four>
        <SEOH1>What clients say about us</SEOH1>
      </Four>
    </Row>

    <RowGroup>
      <Row>
        <One>
          <ClutchWidget
            className="clutch-widget"
            data-url="https://widget.clutch.co"
            data-widget-type="2"
            data-height="50"
            data-clutchcompany-id="146248"
          />
          <br/>
          <Link to="https://clutch.co/profile/500tech#reviews">
            Read all reviews
          </Link>
        </One>
        <One>
          They cared about the project just like anyone from our company.
          <Person>Director of Engineering</Person>
        </One>
        <One>
          They were fantastic, and there’s no superlative that could be more
          precise. We were very pleased.
          <Person>Application Engineer</Person>
        </One>
        <One>
          They're very experienced and professional.
          <Person>Head of R&D</Person>
        </One>
      </Row>
      <Row>
        <Two>
          Their technological capability capabilities are the main
          differentiator. As a leader in training, they have access to many
          people working in this field. They do a great job of selecting the
          right people to do the job.
          <Person>CEO</Person>
        </Two>
        <Two>
          They are versatile and bring a wide range of experience and in-depth
          knowledge to their projects at a level that is difficult to find in
          engineers. ... In the rare instance that they don't know about a
          method or technology, they quickly find the information they need and
          move the project along.
          <Person>Founder</Person>
        </Two>
      </Row>
      <Row>
        <OneThird>
          The flexibility and ability to adapt to project changes—as well as to
          adapt to changing timelines and requirements—are exceptional.
          <Person>Development Team Lead</Person>
        </OneThird>
        <OneThird>
          Their knowledge of JavaScript tech stacks and their development team’s
          capabilities are impressive. They’re the best team I’ve ever worked
          with in the JavaScript space.
          <Person>Managing Partner</Person>
        </OneThird>
        <OneThird>
          Every person we’ve used from their team is talented and skilled. They
          manage to bring in high-quality people.
          <Person>CTO</Person>
        </OneThird>
      </Row>
      <Row>
        <Two>
          While other companies give us proposals, none of them seemed to grasp
          the complexity of what we were trying to do. In contrast, the proposal
          from 500Tech showed that they understood the project from the
          beginning. They gave us ideas that made us change some of our
          assumptions.
          <Person>Project Manager</Person>
        </Two>
        <Two>
          Their level of knowledge is impressive. They take their work and code
          quality very seriously. You can give them a task and they run with it.
          You don’t have to check up on them all the time. It gets done fast.
          <Person>VP R&D</Person>
        </Two>
      </Row>
    </RowGroup>
    <ContactForm />
  </Layout>
)

const ClutchWidget = styled.div`
  // background-color: ${CONTRAST_COLOR};
  max-width: 280px;
`

const Person = styled.div`
  font-size: 14px;
  margin-top: 5px;
  color: ${CONTRAST_COLOR};

  &:before {
    content: '–';
    margin-right: 5px;
  }
`
